@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-M';
    src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-R';
    src: url('../fonts/Poppins-Regular.woff') format('woff2'),
        url('../fonts/Poppins-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-H';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


body {
    zoom: 0.8;
    overflow: hidden;
    width: auto;
}

.container {
    margin-left: 17em;
    margin-right: 17em;
    overflow: hidden;
    margin-top: -3%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text {
    margin-top: 10%;
}

.first-line,
.second-line {
    display: block;
    margin-bottom: -22px;
}




.group {
    width: 100%;
    max-width: 100%;
    height: auto;
    animation: slideInFromBottom 1s ease-out;
}

.text h1 {
    line-height: 1.4;
    font-family: 'Poppins-M';
    font-size: 50px;
    margin-bottom: 6.5%;
    letter-spacing: 1.6px;
}

.grp {
    z-index: 1;
}

.text p {
    line-height: 1.8;
    font-family: 'Poppins-R';
    color: #535353;
    font-size: 18px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.get-started {
    background-color: #FFA200;
    width: 140px;
    height: 45px;
    border: none;
    font-family: 'Poppins';
    font-weight: 600;
    margin-top: 1.8%;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.get-started:hover {
    cursor: pointer;
    background-color: black;
    color: white;
}

@keyframes slideInFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (min-width: 1280px) {
    .container {
      
    }

    .text h1 {
        font-size: 60px; /* Increase font size for larger screens */
    }
}



@media screen and (max-width: 768px) {
    body {
        overflow-x: hidden;
        /* Prevent horizontal scrolling */
    }

    .container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .content {
        align-items: center;
        /* Center content horizontally */
    }

    .text h1 {
        font-size: 50px;
        /* Smaller font size for mobile */
        line-height: 0.85;
        margin-bottom: 10%;

    }

    .text p {
        font-size: 20px;
        /* Smaller font size for mobile */
        line-height: 2;
        margin-top: -4%;
        text-align: justify;
    }

.grp {
    width: 100%;
    max-width: 100%;
    height: auto;
}

    .first-line,
    .second-line {
        margin-bottom: 10px;
        /* Adjust spacing between lines */
    }
}


/* Media queries for responsive design */
@media screen and (min-width: 768px) {

    body {
        overflow-x: hidden;

    }

    .content {
        flex-direction: row;
        /* Display items side by side on larger screens */
    }
    
.first-line,
.second-line {
    display: block;
}


.test h1 {
    line-height: 1.4;
    font-family: 'Poppins-M';
    font-size: 50px;
    /* Adjust font size for smaller screens */
    margin-bottom: 4.5%;
    letter-spacing: 1.6px;
}
        .text {
            margin-right: 2rem;
            /* Add spacing between text and image */
        }

        .group {
            width: auto;
            /* Allow the image to adjust its width */
            height: 100%;
            /* Ensure the image takes up full height */
        }

        .text h1 {
            font-size: 74px;

            /* Restore original font size */
        }

    }


@media (max-width: 480px) {
    body {
        overflow-x: hidden;
        /* Prevent horizontal scrolling */
    }

    .container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .content {
        align-items: center;
        /* Center content horizontally */
    }

    .text h1 {
        font-size: 40px;
        /* Smaller font size for mobile */
        line-height: 0.85;
        margin-bottom: 10%;

    }

    .text p {
        
        width: auto;
        font-size: 1.1rem;
        line-height: 1.2;
        margin-top: -4%;
        text-align: justify;
        white-space: normal; /* Reset to normal for mobile */
        overflow: visible; /* Reset to visible for mobile */
        text-overflow: clip; /* Reset to clip for mobile */
 
    }
.para-cont {
    white-space: nowrap;
}


    .first-line,
    .second-line {
        margin-bottom: 10px;
        /* Adjust spacing between lines */
    }
    .para-cont br {
        display: none;
    }

}




/* landscape and tab */

@media screen and (min-width: 768px) and (max-width: 1024px) {
   
.container {
    overflow: hidden;
    margin-left: 8.4%;
    width: 100%;
    
}


.content {
    display: flex;
    flex-direction: row;
    /* Stack items vertically on small screens */
    justify-content: space-between;
    align-items: center;
    margin: 0; 
}


.text {
    margin-top: -15%;
}

.first-line,
.second-line {
    display: block;
    line-height: 1.1;
    margin-bottom: 0%;
    

    /* Reduce the bottom margin of the spans */
}

.group {
    width: 100%;
    /* Make the image responsive */
    max-width: 60%;
    /* Ensure the image does not exceed its container */
    height: auto;
    /* Maintain aspectratio */
    animation: slideInFromBottom 1s ease-out;
    
}

.text h1 {
    line-height: 1.4;
    font-family: 'Poppins-M';
    font-size: 50px;
    /* Adjust font size for smaller screens */
    margin-bottom: 4.5%;
    letter-spacing: 1.6px;
}
.grp {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.grp {
    z-index: 1;
    /* Ensure group image appears above background image */
}

.text p {
    width: 80%;
    line-height: 1.2;
    font-family: 'Poppins-R';
    color: #535353;
    font-size: 18px;
    margin-top: -1rem;
    white-space: normal; /* Reset to normal for mobile */
    overflow: visible; /* Reset to visible for mobile */
    text-overflow: clip; /* Reset to clip for mobile */
}

.para-cont br {
    display: none;
}

.get-started {
    background-color: #FFA200;
    width: 8rem;
    height: 2.5rem;
    border: none;
    font-family: 'Poppins';
    font-weight: 600;
    margin-top: 0%;
    transition: background-color 0.5s ease, color 0.5s ease;
    /* Transition duration set to 1 second */

}

.get-started:hover {
    cursor: pointer;
    background-color: black;
    color: white;
}
}
@media screen and (min-width: 480px) and (max-width: 768px) and (orientation: landscape) {
    .container {
        overflow: hidden;
        margin-left: 8.4%;
        width: 100%;
    }

    .content {
        display: flex;
        flex-direction: row;
        /* Stack items vertically on small screens */
        justify-content: space-between;
        align-items: center;
        margin: 0; 
    }

    .text {
        margin-top: -15%;
    }

    .first-line,
    .second-line {
        display: block;
        line-height: 1.1;
        margin-bottom: 0%;
        /* Reduce the bottom margin of the spans */
    }

    .group {
        width: 100%;
        /* Make the image responsive */
        max-width: 60%;
        /* Ensure the image does not exceed its container */
        height: auto;
        /* Maintain aspect ratio */
        animation: slideInFromBottom 1s ease-out;
    }

    .text h1 {
        line-height: 1.4;
        font-family: 'Poppins-M';
        font-size: 40px;
        /* Adjust font size for smaller screens */
        margin-bottom: 4.5%;
        letter-spacing: 1.6px;
    }

    .grp {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .grp {
        z-index: 1;
        /* Ensure group image appears above background image */
    }

    .text p {
        width: 80%;
        line-height: 1.2;
        font-family: 'Poppins-R';
        color: #535353;
        font-size: 14px;
        margin-top: 0rem;
        white-space: normal; /* Reset to normal for mobile */
        overflow: visible; /* Reset to visible for mobile */
        text-overflow: clip; /* Reset to clip for mobile */
    }

    .para-cont br {
        display: none;
    }

    .get-started {
        background-color: #FFA200;
        width: 8rem;
        height: 2.5rem;
        border: none;
        font-family: 'Poppins';
        font-weight: 600;
        margin-top: 2%;
        transition: background-color 0.5s ease, color 0.5s ease;
        /* Transition duration set to 1 second */
    }

    .get-started:hover {
        cursor: pointer;
        background-color: black;
        color: white;
    }
}


@media screen and (min-width: 768px) and (max-width: 1281px) and (orientation: landscape) {
   
    .container {
        overflow: hidden;
        margin-left: 8.5%;
        width: 100%;
    }
    .group {
        width: 100%;
        /* Make the image responsive */
        max-width: 65%;
        /* Ensure the image does not exceed its container */
        height: auto;
        /* Maintain aspectratio */
        animation: slideInFromBottom 1s ease-out;
        
    }

}


@media screen and (min-width: 1024px) and (max-width: 1281px) {
 

    .text p {
        width: 100%;
        line-height: 1.2;
        font-family: 'Poppins-R';
        color: #535353;
        font-size: 18px;
        margin-top: -1rem;
        white-space: normal; /* Reset to normal for mobile */
        overflow: visible; /* Reset to visible for mobile */
        text-overflow: clip; /* Reset to clip for mobile */
    }
    
    .para-cont br {
        display: none;
    }

    .grp {
        width: 90%;
        max-width: 100%;
        height: auto;
    }
    .container {
        margin-right: 1em;
        overflow: hidden;
        margin-top: -3%;
    }

    
}