@font-face {
    font-family: 'Poppins-R';
    src: url('../fonts/Poppins-Regular.woff') format('woff2'),
      url('../fonts/Poppins-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
  }


  
.counter h1 {
    font-family: 'Poppins-R';
    font-size: 73px;
    margin-bottom: -7%;
}