@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-R';
  src: url('../fonts/Poppins-Regular.woff') format('woff2'),
    url('../fonts/Poppins-Regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}


.service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto; /* Adjusted to auto for responsive height */

  position: relative;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  margin-left: 14%;
  margin-right: 14%;
  padding-bottom: 2rem; /* Add padding to the bottom */

}

.wrap {
  display: flex;
  align-items: center;
}

.horizontal-container {
  display: flex;
  will-change: transform;
  width: 100%;

  justify-content: space-between;
  
}

.service1 {
  flex: 1 0 calc(33.333% - 1.6%);
  max-width: calc(33.333% - 1.6%);
  height: 480px;
  border: 1px solid #999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1.6%;
  cursor: pointer;
}

.service1:last-child {
  margin-right: 0;
}

.img-con {
  /* width: 330px; */
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-con img {
  max-width: 22%;
  object-fit: contain;
  padding-bottom: 10%;
}

.img-con .line {
  height: 2px;
}

.service-content {
  display: flex;
  flex-direction: column;
  margin-top: -10%;
  width: 75%;
}

.service-content h1 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 34px;
}

.service-content p {
  font-size: 18px;
  width: 100%;
  height: 102px;
  font-family: 'Poppins-R';
  margin-top: -7%;
  line-height: 1.8;
}

.arrow-icon-left {
  color: #999999;
  transition: color 0.3s ease;
}

.arrow-icon-left:hover {
  color: black;
}

.arrow-icon-right {
  color: #999999;
  transition: color 0.3s ease;
}

.arrow-icon-right:hover {
  color: black;
}

.arrow-container {
  display: flex;
  gap: 70%;
  position: absolute;
  right: 5%;
}

.arrow-icon-left {
  transition: transform 0.3s ease;
}

.arrow-icon-right {
  transition: transform 0.3s ease;
}

.arrow-container:hover .arrow-icon-left {
  transform: translateX(-5px);
}

.arrow-container:hover .arrow-icon-right {
  transform: translateX(5px);
}

h1 {
  font-family: 'Poppins-SemiBold';
  font-size: 60px;
}

p {
  font-family: 'Poppins-R';
  font-size: 18px;
}

.service-bg {
  width: 100%;
  height: auto;
  position: absolute;
}

.blue {
  background-color: #01143C;
  margin-top: 6%;
}

.blue-content {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
}

.blue-content h1 {
  display: flex;
  position: relative;
  color: white;
  font-family: 'Poppins-SemiBold';
  font-size: 60px;
  justify-content: center;
  margin-top: 8%;
  line-height: 1.27;
  margin-bottom: 4%;
}

.counters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 8%;
}

.counters {

  gap: 20px;
}


.counter-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.counter-title p {
  color: white;
  font-family: Poppins-R;
  font-size: 22px;
}
.view-more-container {
  display: none;
}
.view-more-btn {
  cursor: pointer;
  display: none; /* Hide by default */
font-size: 0.8rem;
  margin-top: 10%;
  background-color: #FFA200;
  width: 100px;
  height: 40px;
  border: none;
  font-family: 'Poppins';
  font-weight: 600;
  transition: background-color 0.5s ease, color 0.5s ease;
  padding: 10 0px;
}

.view-more-btn:hover {
  background-color: #0056b3;
}



@media screen and (min-width: 480px) and (max-width: 768px) and (orientation : landscape) {
.img-con {
 margin-top: 5%;
}

.view-more-btn {
  display: block; /* Show only on mobile screens */
}
}

@media screen and (max-width: 1024px) {

  .view-more-btn {
    display: block; /* Show only on mobile screens */
  }
  .service-container {
    margin-left: 2%;
    margin-right: 2%;
    height: auto;
  }

  .horizontal-container {
    flex-direction: column;
    align-items: center;
    height: 60%;
    width: 70%;
  }

  .service1 {
    flex: 1 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .img-con,
  .service-content {
    width: 80%;
    align-items: center;
    text-align: center;
  }

  .img-con img {
    margin-bottom: -7%;
    margin-top: 2%;
  }
  .view-more-container {
    display: block;
  }

  .img-con {

  }

  .service-content {
    margin-top: -4%;
  }
  .service-content h1 {
    font-size: 3rem;
    margin-bottom: 8%;
    
  }

  .service-content p {
    font-size: 1.5rem;
    width: 100%;
    height: auto;
  }

  .arrow-container {

    display: none;
  }

  h1 {
    font-size: 48px;
  }



  .blue-content h1 {
    font-size: 48px;
  }

  .counters {
    flex-direction: column;
    align-items: center;
  }

  .counter-title p {
    font-size: 20px;
  }
}


@media screen and (max-width: 768px) {
  .service-container {
    margin-left: 2%;
    margin-right: 2%;
  }

  .horizontal-container {
    flex-direction: column;
    align-items: center;
    height: 50%;
    width: 90%;
  }

  .service1 {
    flex: 1 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .view-more-btn {
    display: block; /* Show only on mobile screens */
  }
  .view-more-container {
    display: block;
  }
  .img-con,
  .service-content {
    width: 80%;
    align-items: center;
    text-align: center;
  }

  .img-con img {
    margin-bottom: -7%;
    margin-top: 2%;
  }

  .service-content {
    margin-top: 2%;
  }
  .service-content h1 {
    font-size: 3rem;
    margin-bottom: 8%;
    
  }

  .service-content p {
    font-size: 1.5rem;
    width: 100%;
    height: auto;
  }

  .arrow-container {

    display: none;
  }

  h1 {
    font-size: 48px;
  }



  .blue-content h1 {
    font-size: 48px;
  }

  .counters {
    flex-direction: column;
    align-items: center;
  }

  .counter-title p {
    font-size: 20px;
  }
}

/* @media screen and (min-width: 480px) and (max-width: 768px) {
  .service-container {
    margin-left: 2%;
    margin-right: 2%;
  }

  .wrap h1 {
    font-size: 34px;
  }

  .horizontal-container {
    flex-direction: column;
    align-items: center;
    height: 80%;
    width: 70%;
  }

  .service1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    
    height: 45%;
    margin-bottom: 2%;
    
    
  }

  .img-con,
  .service-content {
    width: 50%;
    align-items: center;
    text-align: center;
  }


  .img-con img {
    margin-bottom: -12%;
    margin-top: 4.5%;
  }

  .service-content {
    margin-top: 0;
    width: 100%;
    
  }

  .service-content h1 {
    font-size: 24px;
    margin-bottom: 10%;
    margin-top: 8%;
  }

  .service-content p {
    font-size: 14px;
    width: 50%;

  }

.arrow-container {
  display: none;
}

  h1 {
    font-size: 36px;
  }

  p {
    font-size: 16px;
  }

  .blue-content h1 {
    font-size: 34px;
    text-align: center;
    margin: 2rem 2rem;
    margin-bottom: 1rem;
  }

  .blue-content br {
    display: none;
  }

  .counters {
    flex-direction: column;
    align-items: center;
  }

  .counter-title p {
    font-size: 18px;
  }
} */

@media (max-width: 480px) {
  .service-container {
    margin-left: 2%;
    margin-right: 2%;
  }
  .view-more-container {
    display: block;
  }
  .wrap h1 {
    font-size: 34px;
  }
  .view-more-btn {
    display: block; /* Show only on mobile screens */
  }
  .horizontal-container {
    flex-direction: column;
    align-items: center;
    height: 40%;
    width: 80%;
  }

  .service1 {
    flex: 1 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .img-con,
  .service-content {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .img-con img {
    margin-bottom: -10%;
    margin-top: 6%;
  }

  .service-content h1 {
    font-size: 24px;
    margin-top: 5%;
    margin-bottom: 10%;
  }

  .service-content p {
    font-size: 14px;
    width: 90%;
  }

  .arrow-container {
    gap: 10%;
    right: 2.5%;
    display: none;
  }

  h1 {
    font-size: 36px;
  }

  p {
    font-size: 14px;
  }

  .blue-content h1 {
    font-size: 34px;
    text-align: center;
    margin: 2rem 2rem;
    margin-bottom: 1rem;
  }

  .blue-content br {
    display: none;
  }

  .counters {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }


  .counter-title p {
    font-size: 18px;
  }
}



@media screen and (max-width: 1024px) and (orientation: landscape) {

  .service-container {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 0%;
    height: auto;
  }
  .view-more-container {
    display: block;
  }

  .horizontal-container {
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 60%;
  }
  .view-more-btn {
    display: block; /* Show only on mobile screens */
  }

}