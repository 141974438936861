/* Footer.css */

body {
  overflow-x: hidden;
}

.footer-center a,
.footer-right a {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color from parent */
}
.footer-container {
    width: 100%;
    height: auto;
    padding-top: 5.5%;
    display: flex;
    padding-left: 13%;
    
  }

  .footer-top-container {
    background-color: #01143C;
    padding-bottom: 1.5%;

  }
  
  .footer-center,
  .footer-right {
    margin-top: 2.8%;
  }
  
  .footer-heading {
    flex: 0 0 50%;
  }
  
  .footer-center {
    flex: 0 0 25%;
    margin-left: -10rem;
    line-height: 1.2;
    margin-right: 2%;
  
  }

  .footer-loc-p {
    line-height: 1.6;
  }
  
  .footer-right {
    flex: 0 0 25%;
    margin-left: 0;
    padding-left: 0;
  }

  .footer-right:hover {
    cursor: pointer;
  }
  
  .footer-heading h1 {
    color: white;
    font-family: 'Poppins-SemiBold';
    font-size: 3.5em;
    line-height: 1.3;
    margin-bottom: 3%;
  }
  
  .footer-heading h1 .lets {
    font-family: 'Poppins-SemiBold-Italic';
    text-decoration: underline;
  }
  
  .footer-heading h1 .work-t {
    font-family: 'Poppins-SemiBold-Italic';
  }
  
  .footer-heading p {
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
    color: white;
  }
  
  .footer-up {
    display: flex;
    flex-direction: column;
    margin-bottom: 17%;
  }
  
  .footer-down {
 display: flex;
 justify-content: center;
  }
  .footer-down p {
    font-size: 16px;
    color: white;
    font-family: 'Poppins-SemiBold';
  }
  .footer-right p {
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
    color: white;
  }
  
  .img-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .img-text img {
    height: auto;
    width: auto;
  }
  
  .img-text p {
    color: white;
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
  }
  
  .start {
    background-color: #FFA200;
    width: 160px;
    height: 47px;
    border: none;
    font-family: Poppins;
    font-weight: 600;
    will-change: transform; /* Optimize for animations */
    letter-spacing: 0.5px;
    transition: background-color 0.5s ease, color 0.5s ease; /* Transition duration set to 1 second */

  }
  
  .start:hover {
    cursor: pointer;
    background-color: black;
    color: white;
  }
  
  .location-img {
    margin-top: -4.5%;
  }
  

  @media (max-width: 768px) {
    .footer-container {
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .footer-heading h1 {
      font-size: 48px;
    }
  
    .footer-center,
    .footer-right {
      flex: 0 0 100%;
      margin: 0;
      margin-top: 5%;
    }
  
    .footer-center {
      margin-left: 0;
    }
  
    .footer-heading h1 {
      font-size: 40px;
    }
  
    .footer-heading p,
    .footer-right p,
    .img-text p {
      font-size: 14px;
    }
  
    .footer-up {
      margin-bottom: 10%;
    }
  
    .start {
      width: 140px;
      height: 40px;
    }
  }
  


  @media (max-width: 480px) {
    .footer-container {
      display: flex;
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .footer-heading {
      margin-bottom: 2%;
    }
  
    .footer-center,
    .footer-right {
      flex: 0 0 100%;
      margin: 0;
    }
  
    .footer-center {
      margin-left: 0;
      margin-bottom: 5%;
    }
  
    .footer-heading h1 {
      font-size: 34px;
      line-height: 1.2;
    }
  
    .footer-heading p,
    .footer-right p,
    .img-text p {
      font-size: 16px;
      line-height: 1.5;
    }

    .start {
      width: 150px;
      height: 40px;
      margin-top: 2%;
      margin-bottom: -2%;
      
    }
  }
  
/* Responsive Styles */
@media (min-width: 1024px) and (max-width: 1280px) and (orientation: landscape) {
  .footer-container { 
margin-left: 0%;
margin-right: 0;
    display: flex;
    

  }

  .footer-heading {
    margin-left: 0%;
  }

  .footer-heading h1 {
    font-size: 48px;
  }

  .footer-center,
  .footer-right {
    flex: 0 0 32%;
    margin: 0;
    margin-top: 1.5%;
    
  }

  .footer-center {
margin-left: -17%;
  }

  .footer-heading h1 {
    font-size: 40px;
  }

  .footer-heading p,
  .footer-right p,
  .img-text p {
    font-size: 18px;
  }

  .footer-up {
    margin-bottom: 10%;
  }

  .start {
    width: 140px;
    height: 40px;
  }
}
@media (min-width: 480px) and (max-width: 769px) and (orientation: landscape) {
  
  .start {
    width: 150px;
    height: 40px;
    margin-top: 2%;
    margin-bottom: -2%;
    transition: background-color 0.5s ease, color 0.5s ease; 
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .footer-container {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer-heading {
    margin-bottom: 2%;
  }

  .footer-center,
  .footer-right {
    flex: 0 0 100%;
    margin: 0;
  }

  .footer-center {
    margin-left: 0;
    margin-bottom: 5%;
  }

  .footer-heading h1 {
    font-size: 34px;
    line-height: 1.2;
  }

  .footer-heading p,
  .footer-right p,
  .img-text p {
    font-size: 20px;
    line-height: 1.5;
  }

  .img-text {}

  .start {
    width: 150px;
    height: 40px;
    margin-top: 2%;
    margin-bottom: -2%;
  }
}

@media (min-width: 768px) and (max-width: 1340px) and (orientation: portrait) {
  .footer-container {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer-heading h1 {
    font-size: 50px;
  }


  .footer-center,
  .footer-right {
    flex: 0 0 100%;
    margin: 0;
    
    
  }


  .footer-heading p,
  .footer-right p,
  .img-text p {
    font-size: 20px;
  }

  .footer-up {
    margin-bottom: 5%;
  }

  .start {
    background-color: #FFA200;
    width: 200px;
    height: 47px;
    font-size: 17px;
    will-change: transform; /* Optimize for animations */

  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer-container {
    padding-left: 5rem;
    padding-right: 5rem;
    display: flex;

  }

  .footer-heading h1 {
    font-size: 48px;
  }

  .footer-center,
  .footer-right {
    flex: 0 0 30%;
    margin: 0;
    margin-top: 3%;
    
  }

  .footer-center {
    margin-left: -4rem;
  }


  .footer-heading h1 {
    font-size: 36px;
  }
  .footer-right {
    margin-left: 2rem;
  }
  .footer-heading p,
  .footer-right p,
  .img-text p {
    font-size: 12px;
  }

  .footer-up {
    margin-bottom: 10%;
  }

  .start {
    width: 140px;
    height: 40px;
  }
}
