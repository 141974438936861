@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
    url('../fonts/Poppins-Medium.woff') format('woff');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold-Italic';
  src: url('../fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



.contact-container {
  width: 100%;
  height: auto;
  background-color: #F5F6F8;
  display: flex;
  flex-wrap: wrap; /* Enable wrapping for responsiveness */
}


.contact-container h1 {
  color: #01143C;
  font-family: 'Poppins-SemiBold';
  margin-left: 16rem;
  margin-top: 7.5%;
  line-height: 1.3;
  margin-right: 9rem;
  

}

.lets-c {
  font-size: 60px;
}


.contact-container h1 .great {
  font-size: 60px;
  font-family: 'Poppins-SemiBold-Italic';
}

h2 {
  font-size: 60px;
  font-family: 'Poppins-SemiBold';
}


.contact-right {
  margin-top: 4.5%;

}

.contact-container form {
  display: flex;
  flex-direction: column;
  
}

.contact-container label {
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-container input,
.contact-container textarea {
  background-color: #F5F6F8;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  /* Remove border radius */
  outline: none;
  /* Remove the outline */
  transition: border-bottom 0.3s;
  /* Smooth transition for focus effect */
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-family: 'Poppins-Medium';
  width: 200%;
  /* Set the width to 100% to fill the container */

}

.contact-container button {
  width: 140px;
  height: 45px;
  border: none;
  background-color: #FFA200;
  color: black;
  font-size: 14px;
  font-family: 'Poppins-SemiBold';
  cursor: pointer;
  margin-bottom: 35%;
  margin-top: 10%;
  transition: background-color 0.5s ease, color 0.5s ease;
  /* Transition duration set to 1 second */

}

.contact-container button:hover {
  background-color: black;
  color: white;

}

.contact-container button:disabled {
  background-color: #ccc;
}

.contact-name {
  display: flex;
  justify-content: space-between;
  width: 200%;
  margin-bottom: 2%;
    
}

.phone-email {
  display: flex;
  justify-content: space-between;
  width: 200%;
  margin-bottom: 2%;
}


.contact-name div,
.phone-email div {
  flex: 1;
  margin: 10px;
}
.f-name {
  margin-right: 7%;
  font-size: 14px;

  padding-bottom: 2%;

}

.f-name::placeholder {
  font-size: 14px;
  color: #B4B4B4;

}

.l-name {
  margin-left: 0;
  padding-left: 0;
  padding-bottom: 2%;

}

.l-name::placeholder {
  font-size: 14px;
  color: #B4B4B4;
}

.phone {
  font-size: 14px;
  padding-bottom: 2%;


}

.phone::placeholder {
  font-size: 14px;
  color: #B4B4B4;

}

.email {
  padding-bottom: 1.5%;
  margin-left: 7%;
}

.email::placeholder {
  font-size: 14px;
  color: #B4B4B4;


}

.address {
  padding-bottom: 4%;


}

.address::placeholder {
  font-size: 14px;
  color: #B4B4B4;
}

.proj-desc {
  margin-top: 5%;
}

.proj-desc::placeholder {
  font-size: 14px;
  color: #B4B4B4;
}

.f-name,
.l-name,
.phone,
.email,
.address,
.proj-desc {
  margin-bottom: 2%;
  flex-wrap: wrap; /* Enable wrapping for responsiveness */

  /* Adjust margin bottom as needed */
}

@media (max-width: 768px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    
  }

  .contact-right {
    margin-left: -0%;
    margin-top: 0%;
    width: 45%;
    align-items: center;
    margin-right: 0;
 
  }

  .contact-container h1 {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  
  }

  .contact-container h1 .lets-c,
  .contact-container h1 .great {
    font-size: 60px;
    line-height: 0.9;
  
  }

  .contact-container h2 {
    font-size: 50px;
    margin: 0;
  }

  .contact-container form {
    width: 100%;
    margin-left: 0;
    align-self: center;
    margin-top: 10%;
  }

  .contact-name,
  .phone-email {
    flex-direction: column;
    width: 100%;
    
  }

  .l-name{ 
    margin-top:5%;
  }
  .email {
    margin-top: 2%;
    margin-left: 0;
  }
  
  .phone {
    margin-bottom: 4%;
  }

  .address {
    margin-top: 3%;
  }
  .f-name,
  .l-name,
  .phone,
  .email,
  .address,
  .proj-desc {
    margin-bottom: 10%;
    width: calc(100% - 4%); /* Adjusting width with left and right margin */
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }
  .f-name::placeholder,
  .l-name::placeholder,
  .phone::placeholder,
  .email::placeholder,
  .address::placeholder,
  .proj-desc::placeholder {
    font-size: 20px;
  } 


  .contact-container button {
    margin-bottom: 18%;
  }
}


@media (min-width: 769px) and (max-width: 1280px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 0;
    
  }

  .contact-right {
    margin-left: -40%;
    margin-top: 0%;
    width: 40%;
    align-items: center;
    margin-right: 0;
 
  }

  .contact-container h1 {
    margin-left: 0;
    margin-right: 0;
  
  }

  .contact-container h1 .lets-c,
  .contact-container h1 .great {
    font-size: 60px;
    line-height: 0.9;
  
  }

  .contact-container h2 {
    font-size: 50px;
    margin: 0;
    text-align: center;
  }

  .contact-container form {
    width: 100%;
    margin-left: 0;
    align-self: center;
    margin-top: 10%;
  }

  .contact-name,
  .phone-email {
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
    
  }

  .l-name{ 
    margin-top:0%;
  }
  .email {
    margin-top: 0%;
    margin-left: 0;
  }
  
  .phone {
    margin-bottom: 0%;
  }

  .address {
    margin-top: 0%;
  }
.proj-desc {
  margin-top: 0;
}

  .f-name,
  .l-name,
  .phone,
  .email,
  .address,
  .proj-desc {
    margin-bottom: 5%;
    width: calc(100% - 4%); /* Adjusting width with left and right margin */
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }
  .f-name::placeholder,
  .l-name::placeholder,
  .phone::placeholder,
  .email::placeholder,
  .address::placeholder,
  .proj-desc::placeholder {
    font-size: 18px;
  } 

  .contact-container button {
    margin-bottom: 18%;
  }
}

@media (max-width: 480px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .contact-right {
    margin-left: -46%;
    margin-top: -1%;
    align-items: center;
    text-align: center;
    padding: 0;

  }

  .contact-container h1 {
    margin-left: 0;
    text-align: center;

  }

  .contact-container h1 .lets-c,
  .contact-container h1 .great {
    font-size: 34px;
    line-height: 0.9;
  
  }

  .contact-container h2 {
    font-size: 30px;
    text-align: center;
  }

  .contact-container form {
    width: 100%;
    margin-left: 0;
  }

  .contact-name,
  .phone-email {
    flex-direction: column;
    width: 100%;
  }

  .l-name{ 
    margin-top:5%;
  }
  .email {
    margin-top: 2%;
    margin-left: 0;
  }
  
  .phone {
    margin-bottom: 4%;
  }

  .address {
    margin-top: 3%;
  }
  .f-name,
  .l-name,
  .phone,
  .email,
  .address,
  .proj-desc {
    margin-bottom: 10%;
    width: calc(100% - 4%); /* Adjusting width with left and right margin */
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }

  .contact-container button {
    margin-bottom: 18%;
  }
}
