@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url('../fonts/Poppins-Thin.woff2') format('woff2'),
        url('../fonts/Poppins-Thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
}

.container-about {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1188px;
    border-width: 1px;
    margin-top: -6.7%;
    position: relative;
}

.first-c {
    width: 100%;
    height: 70%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-about {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.about-img {
    width: 100%;
    height: 876px;
    object-fit: cover;
}

.left-c {
    margin-left: 12%;
    margin-right: 5rem;
    transform: scale(0);
    transition: transform 0.2s ease-out;
}

.left-c.animate {
    transform: scale(1);
}


.right-c {
    margin-right: 13.5%;
    transform: translateX(100%);
    transition: transform 1s ease-out;
}

.right-c.animate {
    transform: translateX(0);
}

.right-c h1 {
    font-family: 'Poppins-SemiBold';
    font-size: 60px;
    color: white;
}

.right-c p {
    width: 36.5rem;
    height: 63px;
    color: white;
    font-family: 'Poppins-Regular';
    font-size: 0.875rem;
    margin-top: -6%;
    margin-bottom: 2%;
    line-height: 1.8;
    text-align: justify;
}

.left-about {
    width: 100%;
    height: 100%;
}

.read-more {
    margin-top: 15%;
    background-color: #FFA200;
    width: 140px;
    height: 45px;
    border: none;
    font-family: 'Poppins';
    font-weight: 600;
    transition: background-color 0.5s ease, color 0.5s ease;
    /* Transition duration set to 1 second */
}

.read-more:hover {
    cursor: pointer;
    background-color: black;
    color: white;
}
.second-c {
    background-color: #F5F6F8;
    display: flex;
    justify-content: center; /* Horizontally center images */
    align-items: center; /* Vertically center images */
    gap: 6rem; /* Add gap between images */
    width: 100%; /* Make the container take full width */
    height: 200px; /* Adjust the height as needed */
    position: relative; /* Position the container relative to its parent */
}

.second-c img {
    width: 180px; /* Set a fixed width for the images */
    height: auto; /* Maintain the aspect ratio */
    transition: transform 0.8s ease-out;
}
.second-c img.animate {
    transform: scale(1);
}

/* Additional Animation Classes */
.fade-in {
    opacity: 0;
    transition: opacity 1s ease-out;
}

.fade-in.animate {
    opacity: 1;
}

.slide-in-right {
    transform: translateX(100%);
    transition: transform 1s ease-out;
}

.slide-in-right.animate {
    transform: translateX(0);
}

.zoom-in {
    transform: scale(0);
    transition: transform 0.8s ease-out;
}

.zoom-in.animate {
    transform: scale(1);
}

.rotate-in {
    transform: rotate(-180deg);
    transition: transform 1s ease-out;
}

.rotate-in.animate {
    transform: rotate(0);
}

@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    60% {
        transform: scale(1.1);
        opacity: 1;
    }

    80% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.bounce-in {
    opacity: 0;
    animation: bounceIn 1s ease-out forwards;
}

@media screen and (max-width: 480px) {
    .container-about {
        height: auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -8rem;
    }

    .left-c {
        margin: 0;
        width: 80%;
        height: 50%;
    }

    .left-about {
        object-fit: contain;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }

    .right-c.animate {
        transform: none;
    }

    .first-c {
        height: auto;
        margin: 0;
        padding: 0;
    }

    .content-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .right-c {
        margin-bottom: 0%;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: none;
        transition: none;      
    }

    .right-c h1 {
        font-size: 34px;
        margin: 0;
    }

    .right-c p {
        width: 90%;
        font-size: 1rem;
        align-items: center;
        text-align: center;
        margin: 1rem -25rem;
        line-height: 1.7; 

    }

    .read-more {
        margin-top: 9rem;
        margin-bottom: -3rem;   
           
    }


    .second-c {

        gap: 0.1rem; /* Add gap between images */
        width: 100%; /* Make the container take full width */
        height: 100px; /* Adjust the height as needed */
        position: relative; /* Position the container relative to its parent */
    }
    
    .second-c img {
        width: 15%; /* Adjust width for mobile screens */
        margin: 5px 0;
    }

    /* .second-c img:nth-of-type(4) {
        max-width: 40px;
    }

    .second-c img:nth-of-type(5) {
        max-width: 55px;
    } */


}
/* typical mobile screens*/
@media screen  and  (min-width:480px ) and (max-width: 767px) {
    .container-about {
        height: auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .left-c {
        margin: 0;
        width: 90%;
        height: 60%;
    }

    .left-about {
        object-fit: contain;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }

    .right-c.animate {
        transform: none;
    }

    .first-c {
        height: auto;
        margin: 0;
        padding: 0;
    }

    .content-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .right-c {
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: none;
        transition: none;      
    }

    .right-c h1 {
        font-size: 34px;
        margin: 0;
    }

    .right-c p {
        width: 100%;
        font-size: 14px;
        align-items: center;
        text-align: center;
        margin: 1rem -25rem;
        line-height: 1.7; 
    }

    .read-more {
        margin-top: 2.5rem;
        margin-bottom: -4rem;      
    }

    .second-c {

        gap: 0.8rem; /* Add gap between images */
        width: 100%; /* Make the container take full width */
        height: 200px; /* Adjust the height as needed */
        position: relative; /* Position the container relative to its parent */
    }
    
    .second-c img {
        width: 17%; /* Adjust width for mobile screens */
        margin: 5px 0;
    }

    /* .second-c img:nth-of-type(4) {
        max-width: 40px;
    }

    .second-c img:nth-of-type(5) {
        max-width: 55px;
    } */

}
@media screen and (min-width: 1024px) and (max-width: 1281px) {
    .container-about {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;
        border-width: 1px;
        margin-top: -5.8rem;
        position: relative;
        
    }

    .first-c {
        width: 100%;
        height: 70%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-about {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 12%;
margin-left: 25%;
    }

    .about-img {
        width: 100%;
        height: 90%;
        object-fit: cover;
    }

    .left-c {
        margin-left: 0%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .right-c {
        width: 50%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .right-c h1 {
        font-family: 'Poppins-SemiBold';
        font-size: 280%;
        color: white;
        text-align: center;
    }

    .right-c p {
        width: 40rem;
        height: auto;
        color: white;
        font-family: 'Poppins-Regular';
        font-size: 1.5rem;
        margin-top: -2rem;
        margin-bottom: 1%;
        line-height: 2.5rem;
        text-align: center;
    }

    .left-about {
        width: 90%;
        height: 90%;
    }

    .read-more {
        margin-top: 2%;
        background-color: #FFA200;
        width: 12rem;
        height: 3rem;
        border: none;
        font-family: 'Poppins';
        font-size: 1rem;
        /* Transition duration set to 1 second */
        align-self: center;
        font-size: 1.2rem;
    }

    .read-more:hover {
        cursor: pointer;
        background-color: black;
        color: white;
    }

    .second-c {

        gap: 0.8rem; /* Add gap between images */
        width: 100%; /* Make the container take full width */
        height: 200px; /* Adjust the height as needed */
        position: relative; /* Position the container relative to its parent */
    }
    
    .second-c img {
        width: 17%; /* Adjust width for mobile screens */
        margin: 5px 0;
    }

}
/* Tablet and small desktop screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container-about {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;
        border-width: 1px;
        margin-top: -5.8rem;
        position: relative;
        
    }

    .first-c {
        width: 100%;
        height: 70%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-about {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 12%;
margin-left: 25%;
    }

    .about-img {
        width: 100%;
        height: 90%;
        object-fit: cover;
    }

    .left-c {
        margin-left: 0%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .right-c {
        width: 50%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .right-c h1 {
        font-family: 'Poppins-SemiBold';
        font-size: 280%;
        color: white;
        text-align: center;
    }

    .right-c p {
        width: 30rem;
        height: auto;
        color: white;
        font-family: 'Poppins-Regular';
        font-size: 1rem;
        margin-top: -2rem;
        margin-bottom: 1%;
        line-height: 1.5rem;
        text-align: center;
    }

    .left-about {
        width: 90%;
        height: 90%;
    }

    .read-more {
        margin-top: 2%;
        background-color: #FFA200;
        width: 10rem;
        height: 2.5rem;
        border: none;
        font-family: 'Poppins';
        font-size: 1rem;
        /* Transition duration set to 1 second */
        align-self: center;
    }

    .read-more:hover {
        cursor: pointer;
        background-color: black;
        color: white;
    }
    .second-c {

        gap: 1.2rem; /* Add gap between images */
        width: 100%; /* Make the container take full width */
        height: 200px; /* Adjust the height as needed */
        position: relative; /* Position the container relative to its parent */
    }
    
    .second-c img {
        width: 15%; /* Adjust width for mobile screens */
        margin: 5px 0;
    }

/*     
    .second-c img:nth-of-type(4) {
        max-width: 4%;
    }

    .second-c img:nth-of-type(5) {
        max-width: 6%;} */
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container-about {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;
        border-width: 1px;
        margin-top: -6rem;
        position: relative;
        
    }


}
@media screen and (min-width: 480px) and (max-width: 768px) and (orientation: landscape) {
    .right-c p {
        width: 90%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
.container-about {
    margin-top: -6.5rem;
}
}

@media screen and (min-width: 1024px) and (max-width: 1280px) and (orientation: landscape) {
    .container-about {
        margin-top: -9rem;
    }
    
    }