@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
    url('../fonts/Poppins-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold-Italic';
  src: url('../fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ContactPg-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -10%;
}

.map {
  width: 100%;
  height: auto;
  margin-top: 4%;
}

.contact-pg-h {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -3%;
text-align: center;
  color: #01143C;
  font-family: 'Poppins-SemiBold';

  
}

.contact-pg-rh h1 {
  font-family: 'Poppins-SemiBold';
  line-height: 1.2;
  font-size: 34px;
  margin-top: 14%;
}



.contact-pg-rh p {
  margin-top: 7%;
  line-height: 1.4;
  font-size: 26px;
  font-family: 'Poppins-SemiBold';
  margin-top: -1%;
  margin-bottom: 9%;
}

.contact-pg-h h1 .great-pg {
  font-family: 'Poppins-SemiBold-Italic';
}


.contact-pg-h .lets-c-pg {
  font-size: 60px;
}


.ContactPg-container h1 .great-pg {
  font-size: 60px;
  font-family: 'Poppins-SemiBold-Italic';
}


h2 {
  font-size: 60px;
  font-family: 'Poppins-SemiBold';
  margin-top: 12%;
}

.ContactPg-right {
  margin-left: 8%;
}

.ContactPg-container form {
  display: flex;
  flex-direction: column;
}

.ContactPg-container label {
  margin-bottom: 10px;
  font-weight: bold;
}

.ContactPg-container input,
.ContactPg-container textarea {
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  outline: none;
  transition: border-bottom 0.3s;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-family: 'Poppins-Medium';
  width: 200%;
}

.ContactPg-container button {
  width: 140px;
  height: 45px;
  border: none;
  background-color: #FFA200;
  color: black;
  font-size: 14px;
  font-family: 'Poppins-SemiBold';
  cursor: pointer;
  margin-bottom: 15%;
  margin-top: 10%;
}

.ContactPg-container button:disabled {
  background-color: #ccc;
}

.ContactPg-name,
.phone-email-pg {
  display: flex;
  justify-content: space-between;
  width: 200%;
  margin-bottom: 2%;
}





.f-name-pg,
.l-name-pg,
.phone-pg,
.email-pg,
.address-pg,
.proj-desc-pg {
  margin-bottom: 2%;
}



.f-name-pg {
  margin-right: 7%;
  font-size: 14px;
  padding-bottom: 2%;

}

.f-name-pg::placeholder,
.l-name-pg::placeholder,
.phone-pg::placeholder,
.email-pg::placeholder,
.address-pg::placeholder,
.proj-desc-pg::placeholder {
  font-size: 14px;
  color: #B4B4B4;
}

.l-name-pg {
  margin-left: 0;
  padding-left: 0;
  padding-bottom: 2%;

}

.phone-pg {
  padding-bottom: 2%;
}

.email-pg {
  padding-bottom: 1.5%;
  margin-left: 7%;
}

.address-pg {
  padding-bottom: 4%;
}

.proj-desc-pg {
  margin-top: 5%;
}


  /* Small screens (up to 480px) */
  @media (max-width: 480px) {
    .contact-pg-h .lets-c-pg {
      font-size: 40px;
    }
    
    
    .ContactPg-container h1 .great-pg {
      font-size: 40px;
      font-family: 'Poppins-SemiBold-Italic';
    }
    .ContactPg-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }
  
    .contact-right,
    .ContactPg-right {
      margin-left: -46%;
      text-align: center;
    }
  
    .ContactPg-container h1,
    .contact-pg-h h1 {
      margin-left: 0;
      text-align: center;
    }
  
    .ContactPg-container h1 
    {
      font-size: 34px;
      line-height: 0.9;
    }
  
    .ContactPg-container h2,
    .ContactPg-right h2 {
      font-size: 34px;
      text-align: center;
    }
  
    .ContactPg-container form,
    .contact-pg-form {
      width: 100%;
      margin-left: 0;
    }

    .contact-name,
    .phone-email,
    .ContactPg-name,
    .phone-email-pg {
      flex-direction: column;
      width: 100%;
    }
  
    .l-name,
    .l-name-pg {
      margin-top: 5%;
    }
  
    .email,
    .email-pg {
      margin-top: 2%;
      
    }

    .email-pg {
      margin-left: 0;
    }
  
    .phone,
    .phone-pg {
      margin-bottom: 4%;
    }
  
    .address,
    .address-pg {
      margin-top: 3%;
    }
  
    .f-name,
    .l-name,
    .phone,
    .email,
    .address,
    .proj-desc,
    .f-name-pg,
    .l-name-pg,
    .phone-pg,
    .email-pg,
    .address-pg,
    .proj-desc-pg {
      margin-bottom: 10%;
      width: calc(100% - 4%); /* Adjusting width with left and right margin */
      box-sizing: border-box; /* Ensure padding and border are included in width */
    }
  
    .contact-pg-h h1 {
      font-size: 34px;
    }
  
    .contact-pg-rh {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .contact-pg-rh h1 {
      font-size: 24px;
      line-height: 1.1;
      margin-top: 5%;
      text-align: center;
    }
  
    .contact-pg-rh p {
      font-size: 16px;
      margin-top: -1%;
      margin-bottom: 5%;
      text-align: center;
    }
  
    .email {
      margin-top: 4%;
      padding-bottom: 2%;
    }
  
    .phone-email {
      margin-top: 4%;
    }
  
    .ContactPg-container button {
      margin-top: 8%;
      margin-bottom: 8%;
      align-self: center;
    }
  }
/* Medium screens (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  
  .ContactPg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .contact-right {
    margin-left: -46%;
    text-align: center;
  }
  .ContactPg-right {
  margin-left: -46%;
      margin-top: 0%;
      width: 45%;
      align-items: center;
      margin-right: 0;

  }

  

  .ContactPg-container h1,
  .contact-pg-h h1 {
    margin-left: 0;
    text-align: center;
  }

  .ContactPg-container h1 {
    font-size: 34px;
    line-height: 0.9;
  }

  .ContactPg-container h2,
  .ContactPg-right h2 {
    font-size: 40px;
    margin-left: 0;
  }

  .ContactPg-container form,
  .contact-pg-form {
    width: 100%;
    margin-left: 0;
  }

  .contact-name,
  .phone-email,
  .ContactPg-name,
  .phone-email-pg {
    flex-direction: column;
    width: 100%;
  }

  .l-name,
  .l-name-pg {
    margin-top: 0%;
  }

  .email,
  .email-pg {
    margin-top: 1%;
    
  }

  .email-pg {
    margin-left: 0;
  }

  .phone,
  .phone-pg {
    margin-bottom: 0%;
  }

  .address,
  .address-pg {
    margin-top: 0%;
  }
.address-pg {
 padding-b: 2%;
}
  .proj-desc-pg {
    margin-top: 3%;
  }

  .f-name,
  .l-name,
  .phone,
  .email,
  .address,
  .proj-desc,
  .f-name-pg,
  .l-name-pg,
  .phone-pg,
  .email-pg,
  .address-pg,
  .proj-desc-pg {
    margin-bottom: 4%;
    width: calc(100% - 4%); /* Adjusting width with left and right margin */
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }

  .contact-pg-h h1 {
    font-size: 40px;
  }

  .contact-pg-rh {
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }

  .contact-pg-rh h1 {
    font-size: 30px;
    line-height: 1.1;
    margin-top: 5%;
    text-align: center;
  }

  .contact-pg-rh p {
    font-size: 18px;
    margin-top: -1%;
    margin-bottom: 5%;
    text-align: center;
  }

  .email {
    margin-top: 4%;
    padding-bottom: 2%;
  }

  .phone-email {
    margin-top: 4%;
  }

  .ContactPg-container button {
    margin-top: 8%;
    margin-bottom: 8%;
    align-self: center;
  }
  
}

@media (min-width: 769px) and (max-width: 1024px) and (orientation: landscape)  {
  .ContactPg-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    margin: 0;
    
  }

  .ContactPg-right {
    width: 20%;
    margin-right: 20%;
  } 
}


@media (min-width: 769px) and (max-width: 1024px)   {
  .ContactPg-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    margin: 0;
    
  }

  .ContactPg-right {
    width: 20%;
    margin-right: 20%;
  } 
}


@media (min-width: 1024px) and (max-width: 1280px)   {
  .ContactPg-right {
    margin-left: 4%;
  }
  .contact-pg-rh {
    margin-left: -%;
  }

}