@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
      url('../fonts/Poppins-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold-Italic';
  src: url('../fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.contact-container2 {
  height: auto;
  width: 400px;
  background-color: #F5F6F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 2%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding-top: 0;
  padding-bottom: 3%;
}

.contact-container2 h1 {

  color: #01143C;
  font-family: 'Poppins-SemiBold';
  font-size: 24px;
  line-height: 1.3;
}

.lets-c1 {
  font-size: 36px;
}


.contact-container2 h1 .great1 {
  font-size: 36px;
  font-family: 'Poppins-SemiBold-Italic';
}

.contact-container2 p {
  font-family: 'Poppins-SemiBold-Italic';
  margin-bottom: 8%;
  margin-top: -11%;
  color: #01143C;
}

.contact-container2 h1 .great2 {
  font-family: 'Poppins-SemiBold-Italic';
}

.contact-container2 input,
.contact-container2 textarea {
  width: 100%;
  background-color: #F5F6F8;
  border-bottom: 2px solid #ccc; /* Add bottom border */
  border-radius: 0; /* Remove border radius */
  outline: none; /* Remove the outline */
  transition: border-bottom 0.3s; /* Smooth transition for focus effect */
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-family: 'Poppins-Medium';
  margin-bottom: 5.5%;
  padding-bottom: 2%;
}

.contact-container2 button {
  width: 100%;
  height: 45px;
  border: none;
  background-color: #FFA200;
  color: black;
  font-size: 14px;
  font-family: 'Poppins-SemiBold';
  cursor: pointer;
  margin-top: 5%;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.contact-container2 button:hover {
    background-color: black;
    color: white;
}

.contact-container2 button:disabled {
  background-color: #938686;
}

.close-button2 {
  position: absolute;
  top: 0%;
  right: 2%;
  font-size: 30px;
  cursor: pointer;
  color: black;
}

.close-button2:hover {
  color: red;
}

.form2 {
  margin-left: 6%;
  margin-right: 6%;
}

.contact-right2 {
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  
}

/* Overlay for blurring the background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}


@keyframes slideIn {
  from {
      transform: translateY(-50px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@media (max-width: 768px) {
  .contact-container2 {
      width: 90%;
      padding: 1rem;
  }
  .contact-container2 h1 {
      font-size: 20px;
  
  }
  .contact-container2 button {
      width: 100%;
      height: 40px;
      font-size: 12px;
      margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-container2 {
      width: 90%;
      padding: 5% 2%;
  }
  .contact-container2 h1 {
      font-size: 18px;
  }
  .close-button2 {
      top: 5px;
      right: 5px;
  }
  
}

@media (min-width: 768px) and (max-width: 1024px) { 

  .contact-container2 {
    width: 70%;
    padding: 5% 0%;
    height: 40%;
    overflow-y: hidden;
}
.contact-container2 h1 {
    font-size: 18px;
    
}
.close-button2 {
    top: 5px;
    right: 10px;
}

.contact-right2 h2  {
  margin-top: 0;

}
.contact-right2 p {
  margin-top: -4%;
}

.contact-container2 input,
.contact-container2 textarea {
 margin-top: -0%;
  margin-bottom: 3%;
  padding-bottom: 1%;
}

}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 

  .contact-container2 {
    width: 40%;
    height: auto;
    padding: 2% 0%;
}
.contact-container2 h1 {
    font-size: 12px;
    margin-top: 4%;
}
.close-button2 {
    top: 5px;
    right: 10px;
}

.contact-right2 h2  {
  margin-top: 0%;
  font-size: 26px;

}
.contact-right2 p {
  margin-top: -6%;
  font-size: 14px;
}

.contact-container2 input,
.contact-container2 textarea {
 margin-top: -2%;
  margin-bottom: 2%;
  padding-bottom: 1%;
}
}
