@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
      url('../fonts/Poppins-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.work-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work-container h1 {
  margin-top: 6%;
  font-family: 'Poppins-SemiBold';
  font-size: 60px;
}

.work-links  {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Adjust the gap between links as needed */
  margin-bottom: 2%;
  margin-top: -2%;
}

.work-links a {
  margin-right: 1.5rem;
  text-decoration: none;
  color: #545366;
  font-family: 'Poppins-Medium';
  font-size: 14px;
  position: relative;
  padding-bottom: 4px;
}

.work-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.5s ease;
  transform: translateX(-50%);
}

.work-links a:hover::after,
.work-links a.active::after {
  width: 70%;
}

.work-links a:hover,
.work-links a.active {
  font-weight: 700 !important;
  font-family: 'Poppins-Bold';
  color: black; /* Change font color to black on hover */
}

.work-links a:not(:hover)::after {
  width: 0;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust based on your layout */
}

.images-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.content-item {
  position: relative;
  overflow: hidden;
}

.content-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-out;
}

.content-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  z-index: 1;
}

.content-item:hover::before {
  opacity: 1; /* Show overlay on hover */
}

.content-item .overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  z-index: 2;
  text-align: center;
}

.content-item:hover .overlay-text {
  opacity: 1;
}





@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



.work-btn {
  background-color: #FFA200;
  width: 140px;
  height: 45px;
  border: none;
  font-family: 'Poppins-SemiBold';
  margin-top: 2%;
  font-size: 14px;
  transition: background-color 0.5s ease, color 0.5s ease; /* Transition duration set to 1 second */
}

.work-btn:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}


.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  width: auto;
  height: 100%; /* Ensure the content takes the full height of the modal */
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto; /* Allow scrolling if needed */
  padding: 1rem; /* Add some padding to ensure image is not flush against the edges */
}

.modal-content img {
  width: auto;
  height: auto;
  max-width: 100%; /* Increase to double the viewport width */
  max-height: 500%; /* Increase to double the viewport height */
  object-fit: contain; /* Ensure the image maintains its aspect ratio and fits within the viewport */
}


.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 40px;
  cursor: pointer;
  color: white;
  z-index: 1010; /* Ensure it's above the modal content */
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


@media (max-width: 1024px) {
  .work-container {
    padding: 0 3%; /* Reduce padding for smaller screens */
  }

  
  .work-links a {
    margin-right: 1rem; /* Reduce margin for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .images-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
  }

  .work-btn {
    width: 120px; /* Adjust button width for smaller screens */
    height: 40px; /* Adjust button height for smaller screens */
  }
  .modal-content {
    padding: 4% 8%;  
    
   
  }

  .close{
    right: 1.8%;
  }
} 


@media (max-width: 768px) {
  .work-container {
    padding: 0 3%; /* Reduce padding for smaller screens */
  }

  .work-container h1 {
    font-size: 28px; /* Adjust font size for smaller screens */
  }

  .work-links a {
    margin-right: 1rem; /* Reduce margin for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .images-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
  }

  .work-btn {
    width: 120px; /* Adjust button width for smaller screens */
    height: 40px; /* Adjust button height for smaller screens */
  }
  .modal-content {
    padding: 6% 8%;   
  }

  .close {
    position: absolute;
    top: 1%;
    right: 1.8%;
    color: #aaa;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
  }
  
}
@media (max-width: 480px) {

  .work-container {
    margin-bottom: 2%;
  }


  .work-container h1 {
    font-size: 34px; /* Further adjust font size for very small screens */
  }

  .work-links {
    flex-direction: column; /* Change flex direction to column */
    align-items: center; /* Center the links horizontally */
  }

  .work-links a {
    margin-right: 0; /* Remove margin for very small screens */
    margin-bottom: 8px; /* Add margin to the bottom for spacing */
    font-size: 16px; /* Further adjust font size for very small screens */
  }

  .images-grid{
    display: flex;
    flex-direction: column; /* Display images vertically */
    align-items: center; /* Center the images horizontally */

  }

   .content-item {
    width: auto; /* Ensure the container takes up the full width */
    height: auto;
    margin: 2%;
  }


  .work-btn {
    width: 120px; /* Adjust button width for smaller screens */
    height: 40px; /* Adjust button height for smaller screens */
  }

  .modal-content {
    padding: 10% 2%;  
    align-items: center; 
  }

  .modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
    
  .modal-content img {
    width: auto;
    height: auto;
    max-width: 100%; /* Increase to double the viewport width */
    max-height: 100%; /* Increase to double the viewport height */
    object-fit: contain; /* Ensure the image maintains its aspect ratio and fits within the viewport */
  }
  

  
}