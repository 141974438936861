@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-R';
    src: url('../fonts/Poppins-Regular.woff') format('woff2'),
        url('../fonts/Poppins-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}



.policy-pg-map {
    display: flex;
    flex-direction: column;
}

.container-policy-pg {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items horizontally */
    text-align: center;
    /* Center text inside the container */
    margin-top: -4%;
    margin-bottom: 3%;
    margin-left: 14%;
    margin-right: 14%;

}

.container-policy-pg h1 {
    font-size: 60px;
    font-family: 'Poppins-SemiBold';

}

.policy-pg-map h1 {
    align-self: self-start;
    font-size: 36px;
    font-family: 'Poppins-SemiBold';
}

.policy-pg-map p {
    text-align: justify;
    font-size: 16px;
    font-family: 'Poppins-R';
    margin-top: -0.2%;
    line-height: 1.9;
    white-space: pre-line;
    /* Preserve line breaks */
    text-align: left;
    /* Ensure text is left-aligned */
}
