@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-R';
    src: url('../fonts/Poppins-Regular.woff') format('woff2'),
        url('../fonts/Poppins-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}


.container-services-pg {
    margin-top: -4%;
    margin-bottom: 2%;
}

.container-services-pg h1 {
    display: flex;
    justify-content: center;
    margin-bottom: -0.1%;
    font-size: 60px;
    font-family: 'Poppins-SemiBold';
}


.service-pg-map {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    /* Center the container */
    width: 63%;
    /* Adjust the width to control spacing */
    border-bottom: 1px solid #999999;
    /* Add border bottom */
    padding-bottom: 50px;
    /* Add space between border and contents */
    padding-top: 50px;

}

.service-pg-map:last-child {
    border-bottom: none;
}


.img-con-pg {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.img-con-pg img {
    margin-right: 25px;
    /* Adjust margin to control spacing */
    max-width: 22%;
    object-fit: contain;
}

.service-pg-map:nth-child(3) .sp-img-edit {
    max-width: 24%;
    max-height: auto; /* Ensure aspect ratio is maintained */

}

.service-pg-map:nth-child(4) .sp-img-edit {
    max-width: 26%;
    max-height: 26%;
}

.service-pg-map:nth-child(5) .sp-img-edit {
    max-width: 23%;
    max-height: 27%;
}

.service-pg-map:nth-child(6) .sp-img-edit {
    max-width: 22%;
    max-height: 32%;
}
.service-pg-map:nth-child(7) .sp-img-edit {
    max-width: 25%;
    max-height: 26%;
    }

.img-con-pg h1 {
    font-family: 'Poppins-SemiBold';
    font-size: 36px;
    margin: 0;
    /* Remove any default margin */

}


.service-content-pg {
    width: 727px;
}

.service-content-pg p {
    margin-bottom: 5%;
    margin-top: 5%;
    font-size: 16px;
    font-family: 'Poppins-R';
    line-height: 1.9;
    color: #333333;
    margin-left: 1rem;



}

@media (max-width: 480px) {


    .container-services-pg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .service-pg-map {

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container-services-pg h1 {
        font-size: 34px;
    }

    .img-con-pg h1 {
        font-size: 26px;
    }

    .service-content-pg {
        width: 100%;
    }

    .service-content-pg p {
        font-size: 14px;
        text-align: justify;

    }
 

    .service-pg-map:nth-child(3) .sp-img-edit {
        max-width: 24%;
    }
    
    .service-pg-map:nth-child(4) .sp-img-edit {
        max-width: 19%;
        max-height: 18%;
    }
    
    .service-pg-map:nth-child(5) .sp-img-edit {
        max-width: 20%;
        max-height: 22%;
    }
    
    .service-pg-map:nth-child(6) .sp-img-edit {
        max-width: 14%;
        max-height: 18%;
    }
    .service-pg-map:nth-child(7) .sp-img-edit {
        max-width: 20%;
        max-height: 20%;
        }
    
}

/* Medium screens (481px to 768px) */
@media (min-width: 481px) and (max-width: 1024px) {
   

    .container-services-pg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .service-pg-map {

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container-services-pg h1 {
        font-size: 50px;
        margin-top: 0;
    }

    .img-con-pg h1 {
        font-size: 26px;
    }

    .service-content-pg {
        width: 100%;
    }

    .service-content-pg p {
        font-size: 14px;
        text-align: center;

    }

    .service-pg-map:nth-child(3) .sp-img-edit {
        max-width: 24%;
    }
    
    .service-pg-map:nth-child(4) .sp-img-edit {
        max-width: 19%;
        max-height: 18%;
    }
    
    .service-pg-map:nth-child(5) .sp-img-edit {
        max-width: 20%;
        max-height: 22%;
    }
    
    .service-pg-map:nth-child(6) .sp-img-edit {
        max-width: 14%;
        max-height: 18%;
    }
    .service-pg-map:nth-child(7) .sp-img-edit {
        max-width: 20%;
        max-height: 20%;
        }
}

/* Large screens (769px and above) */
/* @media (min-width: 769px) {

    .img-con-pg h1 {
        font-size: 36px;
        font-family: 'Poppins-SemiBold';
    }

    .service-content-pg p {
        font-size: 16px;
    }
} */