WorkPg.css

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
    url('../fonts/Poppins-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


.work-container-pg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7%;
  margin-bottom: 5%;
  padding: 0 3%;
}

.work-title-pg {
  margin-bottom: 3%;
}


.WorkPg-container h1 {
  margin-top: -2%;
  font-family: 'Poppins-SemiBold';
}

.WorkPg-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* Adjust the gap between links as needed */
  margin-bottom: 2%;
  margin-top: -1%;
}

.WorkPg-links a {
  margin-right: 1.5rem;
  text-decoration: none;
  color: #545366;
  font-family: 'Poppins-Medium';
  font-size: 14px;
  position: relative;
  padding-bottom: 4px;
}

.WorkPg-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.5s ease;
  transform: translateX(-50%);
}

.WorkPg-links a:hover::after {
  width: 70%;
}

.WorkPg-links a:hover {
  font-weight: 700 !important;
  font-family: 'Poppins-Bold';
  color: black;
  /* Change font color to black on hover */
}

.WorkPg-links a:not(:hover)::after {
  width: 0;
}

.WorkPg-links a.active {
  font-weight: bold;
  color: black;
}





.images-grid-pg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Adjust based on your layout */
}

.images-grid-pg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.WorkPg-btn {
  background-color: #FFA200;
  width: 140px;
  height: 45px;
  border: none;
  font-family: 'Poppins-SemiBold';
  margin-top: 2%;
  font-size: 14px;
}

.WorkPg-btn:hover {
  cursor: pointer;
}

@media (max-width: 480px) {



  .WorkPg-container h1 {
    font-size: 40px; /* Adjust font size for smaller screens */
  }

  .WorkPg-links {
    flex-direction: column; /* Change flex direction to column */
    align-items: center; /* Center the links horizontally */
  }
  .WorkPg-links a {
    margin-right: 0; /* Remove margin for very small screens */
    margin-bottom: 8px; /* Add margin to the bottom for spacing */
    font-size: 18px; /* Further adjust font size for very small screens */
  }

  .images-grid-pg {
    display: flex;
    flex-direction: column; /* Display images vertically */
    align-items: center; /* Center the images horizontally */
  }

  .content-item-pg {
    width: auto; /* Ensure the container takes up the full width */
    height: auto;
    margin: 5%;
    margin-bottom: 0;
  }

.WorkPg-links a.active {
  font-weight: bold;
  color: black;
}

.WorkPg-links a.active::after {
  width: 70%;
}

}
