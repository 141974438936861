@font-face {
    font-family: 'Poppins-R';
    src: url('../fonts/Poppins-Regular.woff') format('woff2'),
        url('../fonts/Poppins-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}




.about-page-wrapper {
    display: flex;
    flex-direction: column;
}

.container-aboutpg {
    flex: 1;
    display: flex;
    flex-direction: column;

}

.wrap-aboutpg {
    margin-left: 8%;
    margin-right: 6.5%;
}

.container-aboutpg h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: -3%;
    font-family: 'Poppins-SemiBold';
    font-size: 60px;
}


.content-aboutpg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 6%;
}

.content-aboutpg2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 7%;
}


.content-right-aboutpg2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 3%;
    /* Add some space between image and text */
}

.content-aboutpg-img img {
    width: auto;
    height: auto;

}

.content-right-aboutpg,
.content-right-aboutpg2 {
    padding-left: 3%;
}

.content-right-aboutpg p,
.content-aboutpg-img2 p {
    width: 655px;
    margin-bottom: 3%;
    font-size: 16px;
    line-height: 1.8;
    font-family: 'Poppins-R';
}

.content-right-aboutpg2 img {
    width: auto;
    height: auto;
}

.content-right-aboutpg h2,
.content-aboutpg-img2 h2 {
    font-size: 60px;
    font-family: 'Poppins-SemiBold';
    margin: -22px 0 10px;
}


.content-right-aboutpg p {
    
}

.service-bg-sp {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: -1;
}


.blue-content-sp {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    z-index: 1;
}

.blue-content-sp h1 {
    display: flex;
    position: relative;
    color: white;
    font-family: 'Poppins-SemiBold';
    font-size: 60px;
    justify-content: center;
    margin-top: 8%;
    margin-bottom: -3%;
}

.counters-sp {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 2%;
    margin-right: 2%;

    margin: 8% 2%;
}

.counter-title-sp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.counter-title-sp p {
    color: white;
    font-family: 'Poppins-SemiBold';
    font-size: 28px;
}

.counter-title-sp img {
    width: auto;
    height: auto;
    margin-bottom: -3%;
}




.white-aboutpg {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 4%;
    margin-top: -3%;
    margin-bottom: 1%;
    padding-bottom: 4%;
    overflow: hidden;
    /* Ensure no overflow in this section */

}


.left-aboutpg {
    display: flex;
    align-items: center;
    /* Center the text vertically relative to the image */
    justify-content: flex-start;
    /* Align content to the left */
    margin-left: 0;
    /* Ensure the container is aligned to the left */
}

.left-aboutpg img {
    /* Space between the image and the text */
    width: auto;
    height: auto;
    z-index: 0;

}

.left-aboutpg h1 {
    text-align: left;
    /* Ensure the text is left-aligned */
    margin: 0;
    /* Remove default margin */
    z-index: 1;
    position: absolute;
    /* Position the heading absolutely within the container */
    font-family: 'Poppins-SemiBold';
    font-size: 60px;
    line-height: 1.3;
    
}



.right-aboutpg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;

}

.right-aboutpg-top {
    display: flex;
    gap: 5rem;
    margin-top: 6%;
    border-bottom: 1px solid #999999;
    /* Add border bottom to all divs */
    padding-bottom: 6%;
    /* Add space below the border */
}


.right-aboutpg-top:not(:first-child) {
    border-bottom: none;
    /* Remove border bottom from all divs except the first one */
}



.right-aboutpg-top h1 {
    font-family: 'Poppins-SemiBold';
    font-size: 24px;
    margin: 0;
    padding: 0;
}




.right-aboutpg-top p {
    font-family: 'Poppins-R';
    font-size: 16px;
    width: 441px;
    height: auto;
    margin: 0;
    line-height: 1.7;

}

.footer {
    /* Adjust footer styles */
    position: fixed;
    bottom: 0;
    width: 100%;
}




@media (max-width: 480px) {


    .container-aboutpg {
        height: auto;
        margin-bottom: 0;
    }

    .about-page-wrapper {
        overflow: hidden;
        width: 100%;
        margin-bottom: 0;
    }


    .container-aboutpg h1 {
        font-size: 34px;
    }

    .content-aboutpg {
        flex-direction: column;
        align-items: center;
        height: auto;
        width: auto;
    }

    .content-aboutpg2 {
        flex-direction: column-reverse;
        align-items: center;
    }

    .content-right-aboutpg,
    .content-right-aboutpg2 {
        padding-left: 0;
    }

    .content-right-aboutpg p,
    .content-aboutpg-img2 p {
        width: 100%;
        font-size: 14px;
        text-align: center;
    }

    .content-aboutpg-img img,
    .content-right-aboutpg2 img {
        width: 100%;
        margin-top: 3%;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }

    .content-right-aboutpg h2,
    .content-aboutpg-img2 h2 {
        margin: 9px 0;
        font-size: 26px;
        text-align: center;
    }

    .counters-sp img {
        width: 40%;
        height: 40%;
        position: relative;
    }

    .service-bg-sp {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        object-fit: cover;

        /* Ensure it remains behind other content */
    }

    .blue-content-sp {
        margin: 0;
        position: relative;
        z-index: 1;
        height: auto;
        /* Ensure it remains on top */
    }

    .blue-content-sp h1 {
        font-size: 2rem;
        margin: 2rem 1rem;
        text-align: center;
        letter-spacing: 0rem;
        font-family: 'Poppins-SemiBold';
    }

    .blue-content-sp br {
        display: none;
    }

    /* .counters-sp {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    
    } */

    
.counter-title-sp img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0%;
}

    .counter-title-sp p {
        font-size: 1rem;
    }


    .white-aboutpg {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        margin-bottom: 0;
        margin-top: 0rem;
        height: auto;
        width: auto;
        position: relative;
        
    }

    .white-aboutpg h1 {
        margin: 0;
    }


    .left-aboutpg img {
        display: none;

    }

    .left-aboutpg {
        
    }

    .left-aboutpg h1 {
        
        font-size: 32px;
        line-height: 1.2;
        position: static;
        text-align: center;
        margin: 0;
        
    }

    .right-aboutpg-top {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin: 1rem;
    }

    .right-aboutpg p {
        text-align: center;
    }

    .right-aboutpg-top h1 {
        font-size: 18px;
        text-align: center;
    }

    .right-aboutpg-top p {
        width: 90%;
        font-size: 18px;
    }

    .blue-sp {
        width: auto;
        height: auto;
        position: relative;
        margin-bottom: 2rem;
    }

}

/* Medium screens (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {

    .about-page-wrapper {
        margin-left: 2%;
        margin-right: 2%;
    }

    .container-aboutpg h1 {
        font-size: 55px;
    }

    .content-aboutpg,
    .content-aboutpg2 {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10%;

    }
    .content-aboutpg2 {
    flex-direction: column-reverse;
    }

    .content-right-aboutpg2 {
        padding-left: 0; 
        margin-top: 5%;
    }

    .content-right-aboutpg p,
    .content-aboutpg-img2 p {
        width: 100%;
        font-size: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        margin: 0;
    }

    .content-right-aboutpg h2,
    .content-aboutpg-img2 h2 {
        margin: 5% 0;
        font-size: 34px;
        text-align: center;
    }

    .counters-sp img {
        width: 40%;
        height: 40%;
        position: relative;
    }

    .service-bg-sp {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        object-fit: cover;

        /* Ensure it remains behind other content */
    }

    .blue-content-sp {
        margin: 0;
        position: relative;
        z-index: 1;
    
        height: auto;
        /* Ensure it remains on top */
    }

    .blue-content-sp h1 {
        font-size: 2rem;
        margin: 4rem 1rem;
        text-align: center;
        letter-spacing: 0.2rem;
    }

    .blue-content-sp br {
        display: none;
    }

    .counters-sp {
        flex-direction: column;
        align-items: center;
        margin-top: 2%;
    }

    .counter-title-sp p {
        font-size: 20px;
        margin-bottom: 25%;
    }


    .white-aboutpg {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        margin-bottom: 0;
        margin-top: 0rem;
        height: auto;
        width: auto;
        position: relative;
        
    }

    .white-aboutpg h1 {
        margin: 0;
    }


    .left-aboutpg img {
        display: none;

    }



    .left-aboutpg h1 {
        
        font-size: 34px;
        line-height: 1.2;
        position: static;
        text-align: center;
        margin: 0;
        
    }

    .right-aboutpg-top {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin: 1rem;
    }

    .right-aboutpg p {
        text-align: center;
    }

    .right-aboutpg-top h1 {
        font-size: 24px;
        text-align: center;
    }

    .right-aboutpg-top p {
        width: 90%;
        font-size: 20px;
    }

    .blue-sp {
        width: auto;
        height: auto;
        position: relative;
        margin-bottom: 2rem;
    }
}

/* Large screens (769px and above) */
@media (min-width: 769px) and (max-width: 1024px)  {

   
    .about-page-wrapper {
        margin-left: 2%;
        margin-right: 2%;
    }

    .container-aboutpg h1 {
        font-size: 55px;
    }

    .content-aboutpg,
    .content-aboutpg2 {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10%;

    }
    .content-aboutpg2 {
    flex-direction: column-reverse;
    }

    .content-right-aboutpg2 {
        padding-left: 0; 
        margin-top: 5%;
    }

    .content-right-aboutpg p,
    .content-aboutpg-img2 p {
        width: 100%;
        font-size: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        margin: 0;
    }

    .content-right-aboutpg h2,
    .content-aboutpg-img2 h2 {
        margin: 5% 0;
        font-size: 34px;
        text-align: center;
    }

    .counters-sp img {
        width: 40%;
        height: 40%;
        position: relative;
    }

    .service-bg-sp {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        object-fit: cover;

        /* Ensure it remains behind other content */
    }

    .blue-content-sp {
        margin: 0;
        position: relative;
        z-index: 1;
    
        height: auto;
        /* Ensure it remains on top */
    }

    .blue-content-sp h1 {
        font-size: 2rem;
        margin: 4rem 1rem;
        text-align: center;
        letter-spacing: 0.2rem;
    }

    .blue-content-sp br {
        display: none;
    }

    .counters-sp {
        flex-direction: column;
        align-items: center;
        margin-top: 2%;
    }

    .counter-title-sp p {
        font-size: 20px;
        margin-bottom: 25%;
    }


    .white-aboutpg {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        margin-bottom: 0;
        margin-top: 0rem;
        height: auto;
        width: auto;
        position: relative;
        
    }

    .white-aboutpg h1 {
        margin: 0;
    }


    .left-aboutpg img {
        display: none;

    }



    .left-aboutpg h1 {
        
        font-size: 34px;
        line-height: 1.2;
        position: static;
        text-align: center;
        margin: 0;
        
    }

    .right-aboutpg-top {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin: 1rem;
    }

    .right-aboutpg p {
        text-align: center;
    }

    .right-aboutpg-top h1 {
        font-size: 24px;
        text-align: center;
    }

    .right-aboutpg-top p {
        width: 90%;
        font-size: 20px;
    }

    .blue-sp {
        width: auto;
        height: auto;
        position: relative;
        margin-bottom: 2rem;
    }
}


@media (max-width: 1024px) and (orientation: landscape) {

    .container-aboutpg h1 {
        font-size: 50px;
    }

    .service-bg-sp {
        width: 100%;
        height: 90%;
        position: absolute;
        z-index: -1;
    }
    

    .blue-content-sp h1 {
        font-size: 40px;
        margin: 2%;
    }

    .counter-title-sp p {
        color: white;
        font-family: 'Poppins-SemiBold';
        font-size: 20px;
    }
    
    .counter-title-sp img {
        width: auto;
        height: auto;
        margin-bottom: -3%;
    }      
}

@media (min-width: 1025px) and (max-width: 1280px) {
.blue-content-sp h1 {
    margin-top: 5%;
}
.right-aboutpg {
    margin-left: 30%;
}
.wrap-aboutpg {
    margin-left: 2%;
    margin-right: 2%;
}

}

@media (min-width: 1281px) and (max-width: 1367px) {
    .right-aboutpg {

        margin-left: 10%;
    
    }
    

}