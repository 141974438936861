@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-R';
  src: url('../fonts/Poppins-Regular.woff') format('woff2'),
    url('../fonts/Poppins-Regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-MI';
  src: url('../fonts/Poppins-MediumItalic.woff2') format('woff2'),
    url('../fonts/Poppins-MediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.test-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6%;
  width: 75%;
  /* Set the width to 80% to center and ensure it fits within the screen */
  margin-top: -1%;
}

.test-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}

.thorizontal-container {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
  /* Ensure the container takes the full width */
  height: 48%;
  display: flex;
  gap: 2rem;
  /* Add space between containers */
}

.test1 {
  flex: 0 0 calc(50% - 1rem);
  /* Adjust the width to fit within the container with space between */
  box-sizing: border-box;
  border: 1px solid #999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: calc(50% - 1.7rem);

}

.test-content {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 7%
}

.title1 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 31px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}
.title2 {
  font-family: 'Poppins-MI';
  font-size: 20px;
font-weight: 400;
}
.title1 .title2 {
  width: 100%;
  display: block;
  line-height: 1;
  
}


.service-p {
  margin-top: -10%;
  font-size: 20px;
  width: 90%;
  height: 102px;
  font-family: 'Poppins-MI';
  margin-bottom: 16%;
  ;
  margin-top: 0%;
}

.tarrow-icon-left {
  color: #999999;
  transition: color 0.3s ease;
}

.tarrow-icon-left:hover {
  color: black;
}

.tarrow-icon-right {
  color: #999999;
  transition: color 0.3s ease;
}

.tarrow-icon-right:hover {
  color: black;
}

.tarrow-container {
  display: flex;
  gap: 50%;
  position: absolute;
  right: 3.5%;
}

.tarrow-icon-left {
  transition: transform 0.3s ease;
}

.tarrow-icon-right {
  transition: transform 0.3s ease;
}

.tarrow-container:hover .tarrow-icon-left {
  transform: translateX(-5px);
}

.tarrow-container:hover .tarrow-icon-right {
  transform: translateX(5px);
}

.test-wrap h1 {
  font-family: 'Poppins-SemiBold';
  font-size: 60px;
}

.timg-con img {
  width: auto;
  height: auto;
  z-index: 1;
}

.timg-con {
  position: absolute;
  margin-bottom: -23%;
  margin-left: 26.5%;
  transform: translateX(-50%);
}


@media (max-width: 768px) {
  .test-container {
    width: auto;
    height: auto;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 10%;
  }

  .title1 {
    font-size: 26px;
    padding-left: 3%;

  }

  .title1 .title2 {
    font-size: 20px;
    padding: 3%;
  }

  .test-content p {
    font-size: 18px;
    width: 100%;
    height: auto;
    margin-bottom: 5%;
    padding: 3%;

  }

  .test1 {
    margin-right: 0;
    flex: 0 0 calc(50% - 1.6rem);
    /* Adjust the width to fit within the container with space between */
    box-sizing: border-box;
    border: 1px solid #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(50% - 1.7rem);

  }

  .tarrow-container {
    gap: 50%;
    right: 5%;
  }

  .timg-con img {
    width: 10%;
    /* Adjust width for very small screens */
    height: auto;
  }

  .timg-con {
    width: 100%;
    margin-bottom: -30%;
    margin-left: 120%;
    /* Center horizontally */
    transform: translateX(-50%);
  }
}


@media (max-width: 480px) {
  .test-container {
    margin-top: 0%;
    margin-bottom: 0%;
    width: auto;
    height: auto;
  }


  .test1 {
    flex: 0 0 calc(100% - 1rem);
    /* Adjust the width to fit within the container with space between */
    box-sizing: border-box;
    border: 1px solid #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(100% - 1.2rem);

  }

  .test-content {
    padding: 4%;

  }

  .tarrow-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  .test-wrap {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .tarrow-icon-left {
    order: 1;
  }

  .tarrow-icon-right {
    order: 3;
  }

  .test-wrap h1 {
    font-size: 34px;
    order: 2;
  }



  .thorizontal-container {
    align-items: center;
    position: relative;


  }

  .test1 {
    flex: 0 0 100%;
    margin-right: -1.6%;
    border: 1px solid #999999;
  }


  .title1 {
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 22px;
    line-height: 1.2;
    margin: 0;
  }


  .title1 .title2 {
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 18px;
    width: 100%;
    display: block;
    line-height: 1;
    margin: 0;
    padding: 0%;
  }


  .test-content p {
    font-size: 16px;
    height: auto;
    margin-left: 0;

  }


  .timg-con img {
    width: 15%;
    /* Adjust width for very small screens */
    height: auto;
  }

  .timg-con {
    width: 100%;
    margin-bottom: -45%;
    margin-left: 140%;
    /* Center horizontally */
    transform: translateX(-50%);
  }
}

@media(min-width: 769px) and (max-width: 1024px) {
  .timg-con img {
    width: 15%;
    /* Adjust width for very small screens */
    height: auto;
  }

  .timg-con {
    width: 100%;
    margin-bottom: -70%;
    margin-left: 100%;
    /* Center horizontally */
    transform: translateX(-50%);
  }

  .title-area {
    margin-top: 10%;
  }
  .tarrow-container {
    width: 10%;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  }
  @media (max-width: 767px) and (orientation: landscape) {
    .timg-con img {
      width: 10%;
      height: auto;
      z-index: 1;
    }
    
    .timg-con {
      position: absolute;
      margin-bottom: -30%;
      margin-left: 10%;
      transform: translateX(-50%);
    }

    
.title1 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 26px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}

.title1 .title2 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 20px;
  width: 100%;
  display: block;
  line-height: 1;
  margin-bottom: 2%;
  margin-top: -1%;
}


.service-p {
  margin-top: -10%;
  font-size: 16px;
  width: 90%;
  height: 100px;
  font-family: 'Poppins-MI';
  margin-bottom: -1%;
  margin-top: 1%;
}
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .timg-con img {
      width: 10%;
      height: auto;
      z-index: 1;
    }
    
    .timg-con {
      position: absolute;
      margin-bottom: -20%;
      margin-left: 10%;
      transform: translateX(-50%);
    }

    
.title1 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 26px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}

.title1 .title2 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 20px;
  width: 100%;
  display: block;
  line-height: 1;
  margin-bottom: 1%;
}


.service-p {
  margin-top: -10%;
  font-size: 16px;
  width: 95%;
  height: 102px;
  font-family: 'Poppins-MI';
  margin-bottom: -1%;
  ;
  margin-top: 1%;
}
    
  }



  @media(min-width: 1024px) and (max-width: 1281px) {
  
    .timg-con img {
      width: 10%;
      height: auto;
      z-index: 1;
    }
    
    .timg-con {
      position: absolute;
      margin-bottom: -33%;
      margin-left: 20%;
      transform: translateX(-50%);
    }
    .tarrow-container {
      width: 10%;
      display: flex;
      justify-content: space-between;
      margin: 0;
    }
  
  
  }