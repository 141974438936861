@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
         url('../fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins-R';
    src: url('../fonts/Poppins-Regular.woff') format('woff2'),
         url('../fonts/Poppins-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  .container-term-pg {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text inside the container */
    margin-top: -4%;
    margin-bottom: 3%; /* Adjust bottom margin */
    margin-left: 14%; /* Adjust left margin */
    margin-right: 14%; /* Adjust right margin */
  }
  
  .container-term-pg h1 {
    font-size: 60px;
    font-family: 'Poppins-SemiBold';
  }
  
  .term-pg-map {
    display: flex;
    flex-direction: column;
    text-align: left; /* Align text to the left */
  }
  
  .term-pg-map h1 {
    font-size: 36px;
    font-family: 'Poppins-SemiBold';
  }
  
  .term-pg-map p,
  .term-pg-map ul,
  .term-pg-map ul li {
    font-size: 16px;
    font-family: 'Poppins-R';
    white-space: pre-line;
    margin-top: -0.2%;
    line-height: 1.9;
    text-align: justify; /* Justify text */
  }
  
  .term-pg-map ul {
    list-style-type: disc;
    margin-left: 20px; /* Adjust the margin-left as needed */
    padding-left: 0; /* Reset padding to ensure consistent alignment */
  }
  
  .term-pg-map ul li {
    margin-bottom: 10px; /* Adjust bottom margin */
  }
  