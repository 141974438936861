/* Global CSS to prevent overflow */
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}


@media (max-width: 480px) {
    body, html  {
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }
}