@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@keyframes scaleLogo {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes dropLinks {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInButton {
    from {
        transform: translateX(50px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: 14%;
    margin-right: 14%;
    overflow: hidden;
}

img {
    width: 78px;
    height: 80px;
    /* animation: scaleLogo 1s ease-out; */
}

.links a {
    margin-right: 1.5rem;
    text-decoration: none;
    color: #545366;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    position: relative;
    padding-bottom: 4px;
    opacity: 0;
    animation: dropLinks 1s ease-out forwards;
}

.links a:nth-child(1) {
    animation-delay: 0.1s;
}

.links a:nth-child(2) {
    animation-delay: 0.2s;
}

.links a:nth-child(3) {
    animation-delay: 0.3s;
}

.links a:nth-child(4) {
    animation-delay: 0.4s;

}

.links a:nth-child(5) {
    animation-delay: 0.5s;
}

.links a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: black;
    transition: width 0.5s ease;
    transform: translateX(-50%);
}

.links a:hover::after {
    width: 70%;
}

.links a:hover {
    font-weight: 700 !important;
    font-family: 'Poppins-Bold';
    color: black;
}

.links a:not(:hover)::after {
    width: 0;
}

.hire-us {
    margin-left: 1rem;
    background-color: #FFA200;
    width: 140px;
    height: 45px;
    border: none;
    font-family: Poppins;
    font-weight: 600;
    /* animation: slideInButton 1s ease-out forwards; */
    /* animation-delay: 0.5s; */
    transition: background-color 0.5s ease, color 0.5s ease;
    /* Transition duration set to 1 second */

}

.hire-us:hover {
    cursor: pointer;
    background-color: black;
    color: white;

}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}



/* Hamburger icon styles */
.hamburger {
    margin-right: 3%;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 37px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1001;
}

.hamburger div {
    width: 100%;
    height: 4px;
    background: black;
    border-radius: 10px;
    transition: all 0.3s linear;
}

.hamburger.open div:nth-child(1) {
    transform: rotate(45deg) translate(8px, 10px);
}

.hamburger.open div:nth-child(2) {
    opacity: 0;
}

.hamburger.open div:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -5px);
}

@media (max-width: 1280px) {
    nav {
        margin-left: 5%;
        margin-right: 5%;
    }

    .links a {
        font-size: 20px;
    }




    .links.open {
        display: flex;
    }
}
@media (max-width: 768px) and (orientation: landscape) {
    nav {
        flex-direction: row;
    }

    .links {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        position: static;
        width: 100%;
        background: none;
    }

    .links a {
        font-size: 14px;
    }

    .hire-us {
        width: 120px;
        height: 40px;
    }
}

@media (max-width: 480px) {
    .links {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: white;
        transition: transform 0.3s ease;
        transform: translateX(100%);
        align-self: flex-start; /* Align to the start (top) of the container */
    }

    .hamburger {
        display: flex;
    }
    .logo img {
        margin-left: 26%;
        width: 62px;
        height: 65px;    }

    .links a {
        font-size: 30px;
        font-family: 'Poppins-R';
    }

    .links.open {
        transform: translateX(0);
        /* Display the links when the 'open' class is present */
    }


    .links a::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: black;
        transition: width 0.5s ease;
        transform: translateX(-50%);
    }

    .links a:hover::after {
        width: 70%;
    }

    .links a:hover {
        font-weight: 700 !important;
        font-family: 'Poppins-Bold';
        color: black;
    }

    .links a:not(:hover)::after {
        width: 0;
    }


    .links a:not(:last-child) {
        margin-bottom: 1rem;
        /* Add spacing between links */
    }



    .hire-us {
        margin-left: 0;
        height: 45px;
        width: 130px;
        letter-spacing: 1px;
        font-size: 20px;
        font-family: 'Poppins-SemiBold';
        margin-top: 1rem;
        align-self: center; /* Align to the start (top) of the container */

    }


    .blur-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        /* Adjust background color and opacity */
        backdrop-filter: blur(1px);
        /* Adjust the blur amount as needed */
        z-index: 998;
        /* Ensure it's behind the navbar */
    }

    .links.open a {
        animation: fadeInFromLeft 2s ease forwards;
    }
}


@media (min-width: 481px) and (max-width: 767px)  and (orientation: landscape){
    .links {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: white;
        transition: transform 0.3s ease;
        transform: translateX(100%);
        align-self: flex-start; /* Align to the start (top) of the container */
    }

    .hamburger {
        display: flex;
    }
    .logo img {
        margin-left: 26%;
        width: 62px;
        height: 65px;    }

    .links a {
        font-size: 30px;
        font-family: 'Poppins-R';
    }

    .links.open {
        transform: translateX(0);
        margin-top: 5%;

        /* Display the links when the 'open' class is present */
    }


    .links a::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: black;
        transition: width 0.5s ease;
        transform: translateX(-50%);
    }

    .links a:hover::after {
        width: 70%;
    }

    .links a:hover {
        font-weight: 700 !important;
        font-family: 'Poppins-Bold';
        color: black;
    }

    .links a:not(:hover)::after {
        width: 0;
    }


    .links a:not(:last-child) {
        margin-bottom: 1rem;
        /* Add spacing between links */
    }



    .hire-us {
        margin-left: 0;
        height: 45px;
        width: 130px;
        letter-spacing: 1px;
        font-size: 20px;
        font-family: 'Poppins-SemiBold';
        margin-top: 1rem;
        align-self: center; /* Align to the start (top) of the container */

    }


    .blur-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        /* Adjust background color and opacity */
        backdrop-filter: blur(1px);
        /* Adjust the blur amount as needed */
        z-index: 998;
        /* Ensure it's behind the navbar */
    }

    .links.open a {
        animation: fadeInFromLeft 2s ease forwards;
    }

}

.links a,
.hire-us {
    opacity: 1;
}

/* Disable pointer events when contact form is visible */
nav.disabled {
    pointer-events: none;
}

/* Optionally, you can add a visual indicator like reduced opacity */
nav.disabled .links a,
nav.disabled .hire-us {
    opacity: 1;
    cursor: not-allowed;
}